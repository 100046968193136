/* eslint-disable no-debugger */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../../../config/baseURLPath'
import DataTable from 'react-data-table-component'
import { Card, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import CustomInput from '../../utils/CustomInput'
import '../admin.css'
import swal from 'sweetalert2'
import { read, utils } from 'xlsx'
import Papa from 'papaparse'

const GENDERS = {
  Male: 'M',
  Female: 'F',
  Others: 'O',
}

const make_cols = (refstr) => {
  let o = [],
    C = utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i }
  return o
}

const List = (props) => {
  const imageUploader = useRef(null)
  const history = useHistory()
  const [courses, setCourses] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [password, setPassword] = useState('')
  const [activeAdmin, setActiveAdmin] = useState('')

  const [activeId, setActiveId] = useState(props.id)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [institution, setInstitution] = useState(0)
  const [data, setData] = useState([])
  const [cols, setCols] = useState([])
  const [uploadData, setUploadData] = useState([])
  const [loading, setLoading] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [skills, setSkills] = useState([])

  const handleClose = () => {
    setConfirmationModal(false)
  }

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  useEffect(() => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'))
      getRecords(userData.AdminUser.institution_id)
      setInstitution(userData.AdminUser.institution_id)

      fetchSubjects()
      fetchSkills()
      fetchState()
      fetchCities()
      fetchQualification()
      fetchCollege()
      fetchCriteria()
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [cityList, setCityList] = useState([])
  const fetchCities = () => {
    axios({
      method: 'get',
      url: baseURL + 'getCities',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [stateList, setStateList] = useState([])
  const fetchState = () => {
    axios({
      method: 'get',
      url: baseURL + 'getStateList',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response)
        setStateList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchSkills = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSkills',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then((response) => {
      setSkills(response.data.data)
    })
  }

  const [qualificationList, setQualificationList] = useState([])
  const fetchQualification = () => {
    axios({
      method: 'get',
      url: baseURL + 'getQualification',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setQualificationList(response.data.Content)
      })
      .catch((error) => {})
  }

  // Sports List
  const [criteriaList, setCriteriaList] = useState([])
  const fetchCriteria = () => {
    axios({
      method: 'get',
      url: baseURL + 'getPerformanceCriteria',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCriteriaList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [collegeList, setCollegeList] = useState([])
  const fetchCollege = () => {
    axios({
      method: 'get',
      url: baseURL + 'getColleges',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setCollegeList(response.data.Content)
      })
      .catch((error) => {})
  }

  // Skills List
  const [subjectList, setSubjectList] = useState([])
  const fetchSubjects = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects/',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getRecords = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getBeneficiaryByValidator/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCourses(response.data.Content)
      })
      .catch((error) => {})
  }

  const deleteRecord = () => {
    setConfirmationModal(false)
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    axios
      .post(
        baseURL + 'deleteBeneficiary/' + activeId,
        {
          // admin_id: activeId,
          // institution_id: props.id
        },
        jwtHeader,
      )
      .then((response) => {
        getRecords(institution)
        new swal({
          title: 'Done!',
          text: 'Record removed successfully',
          icon: 'success',
          timer: 2000,
          button: false,
        }).then((result) => {})
      })
      .catch((error) => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const addBeneficiary = () => {
    history.push('/validator/beneficiaries-add')
  }

  const successAction = () => {
    if (password.trim() === '') {
      return
    }
    const values = {
      admin_email: activeAdmin,
      password: password.trim(),
    }
    axios
      .post(baseURL + 'auth/updateAdminPass', values, jwtHeader)
      .then((res) => {
        setShowModal(false)
        alert('Password updated successfully')
      })
      .catch((err) => console.log(err))
  }

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    user_email: '',
    role_id: '',
    city_id: '',
  })

  const validateTemplate = (data) => {
    const expectedHeaders = [
      'Beneficiary_First_Name',
      'Beneficiary_Last_Name',
      'Beneficiary_Email',
      'Beneficiary_Phone',
      'Beneficiary_DOB',
      'Aadhar_Number',
      'Current_Street_Number',
      'Current_State',
      'Current_City',
      'Current_Pin_Code',
      'Permanent_Street_Number',
      'Permanent_State',
      'Permanent_City',
      'Permanent_Pin_Code',
      'Enrollment_Number',
      'College',
      'Qualification',
      'Family_Annual_Income(in lacs)',
      'CGPA',
      'Skill_Acquired_Subject',
      'Skill_Acquired_Subject_Skill',
      'Skill_Required_Subject',
      'Skill_Required_Subject_Skill',
      'Gender',
      'Beneficiary_Type',
    ]

    const fileHeaders = data[0].map((header) => header.trim().replace(/\s+/g, '_').toLowerCase())

    const isValidTemplate = expectedHeaders.every((header) =>
      fileHeaders.includes(header.trim().replace(/\s+/g, '_').toLowerCase()),
    )
    return isValidTemplate
  }

  const handleImageUpload = async (e /*:File*/) => {
    setLoading(true)

    const file = e.target.files[0]
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = read(bstr, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      let data = utils.sheet_to_json(ws, { header: 1 })
      const current_cityColumnIndex = 8
      const Current_stateColumnIndex = 7
      const permanent_cityColumnIndex = 12
      const Permanent_stateColumnIndex = 11
      const collegeColumnIndex = 15

      //acquired subject
      const acquiredSubjectColumn = 19
      const acquiredSkillColumn = 20
      const requiredSubjectColumn = 21
      const requiredSkillColumn = 22
      const incorrect_list = []
      const qualificationColumnIndex = 16
      const pcColumnIndex = 18

      if (validateTemplate(data)) {
        const mappedData = data.map((row, index) => {
          if (index === 0) {
            return
          }

          if (row.length === 0) {
            return
          }

          const dob = row[4]

          let dobFormatted = excelDateToJSDate(dob)

          row[4] = dobFormatted

          //current_state
          const mappedState = stateList.find(
            (state) =>
              state.state_name.trim().toLowerCase() ===
              (row[Current_stateColumnIndex] || '').trim().toLowerCase(),
          )
          //permanent_state
          const mappedState2 = stateList.find(
            (state) =>
              state.state_name.trim().toLowerCase() ===
              (row[Permanent_stateColumnIndex] || '').trim().toLowerCase(),
          )
          const mappedPCId = criteriaList.find(
            (pc) => parseFloat(pc.pc_name) === parseFloat(row[pcColumnIndex]),
          )

          if (mappedPCId) {
            row[pcColumnIndex] = mappedPCId.pc_id
          } else {
            row[pcColumnIndex] = ''
          }

          const mappedQualificationId = qualificationList.find(
            (qualification) =>
              qualification.qualification_name.trim().toLowerCase() ===
              (row[qualificationColumnIndex] || '').trim().toLowerCase(),
          )

          if (mappedQualificationId) {
            row[qualificationColumnIndex] = mappedQualificationId.qualification_id
          } else {
            row[qualificationColumnIndex] = 'Not mapped with DB'
          }

          if (mappedState) {
            row[Current_stateColumnIndex] = mappedState.state_id
          } else {
            incorrect_list.push(row)
            return null
          }
          if (mappedState2) {
            row[Permanent_stateColumnIndex] = mappedState2.state_id
          } else {
            incorrect_list.push(row)
            return null
          }

          const mappedCity = cityList.find(
            (city) => city?.city_name === row[current_cityColumnIndex],
          )

          if (mappedCity) {
            row[current_cityColumnIndex] = mappedCity?.city_id
          } else {
            incorrect_list.push(row)
            return null
          }

          const mappedCity2 = cityList.find(
            (city) => city?.city_name === row[permanent_cityColumnIndex],
          )

          if (mappedCity2) {
            row[permanent_cityColumnIndex] = mappedCity2?.city_id
          } else {
            incorrect_list.push(row)
            return null
          }

          let mappedName = ''
          const collegeMapping = (row[collegeColumnIndex] || '').trim() // Trim spaces
          const mappedCollege = collegeList.find((college) => {
            mappedName = (college.college_name || '').trim().toLowerCase()
            return mappedName === collegeMapping.toLowerCase()
          })

          if (collegeMapping !== '' && mappedName === collegeMapping.toLowerCase()) {
            row[collegeColumnIndex] = mappedCollege.college_id
          } else {
            incorrect_list.push(row)
            return null
          }
          //map subject
          const subjectMapping = row[acquiredSubjectColumn]
          let subMap = ''
          const mappedSubject = subjectList.find((subject) => {
            subMap = (subject.subject_name || '').trim().toLowerCase()
            return subMap === subjectMapping.toLowerCase()
          })

          if (mappedSubject) {
            row[acquiredSubjectColumn] = mappedSubject?.subject_id
          }

          // Acquired Skill
          const acquiredSkillMapping = row[acquiredSkillColumn]
          let acqSkills = ''
          const mappedAcquiredSkill = skills.find((skill) => {
            acqSkills = (skill.skill_name || '').trim().toLowerCase()
            return acqSkills === acquiredSkillMapping.toLowerCase()
          })

          if (mappedAcquiredSkill) {
            row[acquiredSkillColumn] = mappedAcquiredSkill?.skill_id
          }

          //map Required skill subject
          const subjectRequiredMapping = row[requiredSubjectColumn]
          let reqSubMap = ''
          const mappedRequiredSubject = subjectList.find((subject) => {
            reqSubMap = (subject.subject_name || '').trim().toLowerCase()
            return reqSubMap === subjectRequiredMapping.toLowerCase()
          })

          if (mappedRequiredSubject) {
            row[requiredSubjectColumn] = mappedRequiredSubject?.subject_id
          }
          // Acquired Skill
          const requiredSkillMapping = row[requiredSkillColumn]
          let reqSkills = ''
          const mappedRequiredSkill = skills.find((skill) => {
            reqSkills = (skill.skill_name || '').trim().toLowerCase()
            return reqSkills === requiredSkillMapping.toLowerCase()
          })

          if (mappedRequiredSkill) {
            row[requiredSkillColumn] = mappedRequiredSkill?.skill_id
          }

          return row
        })
        const csvContent = Papa.unparse(incorrect_list)
        setCsvData(csvContent)
        const mappedDataWithoutNull = mappedData.filter((row) => row !== null && row !== undefined)

        setData(data.filter((item) => item.length > 0))
        setCols(make_cols(ws['!ref']))
        setUploadData(mappedDataWithoutNull)
      } else {
        alert('Invalid template. Please use the correct template.')
      }
      setLoading(false)
      // uploadBeneficiaries(data);
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)

    e.target.value = null
  }

  const downloadCsv = () => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'incorrect_data.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  function excelDateToJSDate(serial) {
    const baseDate = new Date('1899-12-30T00:00:00Z') // Excel base date for Windows
    const daysToAdd = Math.floor(serial) // Ensure it's an integer
    const millisecondsPerDay = 24 * 60 * 60 * 1000
    const offsetMilliseconds = daysToAdd * millisecondsPerDay
    const resultDate = new Date(baseDate.getTime() + offsetMilliseconds)
    const day = resultDate.getDate().toString().padStart(2, '0')
    const month = (resultDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
    const year = resultDate.getFullYear().toString()
    return `${day}/${month}/${year}`
  }

  const uploadBeneficiaries = () => {
    try {
      const data = uploadData

      let data_rec = [...data]
      let records = []

      console.log('data', data)

      if (data.length < 1) {
        alert('No correct data found in excel')
        return
      } else if (data[0].length !== 25) {
        alert('Please check your sheet format')
        return
      }

      for (let i = 0; i < data_rec.length; i++) {
        if (data_rec[i]) {
          console.log(data_rec[0])
          if (data_rec[i].length === 25) {
            // const dobSerial = data_rec[i][4] // Assuming data_rec[i][4] contains the DOB serial number
            // const dobFormatted = excelDateToJSDate(dobSerial)

            let ele = {
              beneficiary_first_name: data_rec[i][0],
              beneficiary_last_name: data_rec[i][1],
              beneficiary_email: data_rec[i][2],
              beneficiary_phone: data_rec[i][3],
              beneficiary_dob: data_rec[i][4],
              aadhar_no: data_rec[i][5],
              current_street_number: data_rec[i][6],
              current_state_id: data_rec[i][7],
              current_city_id: data_rec[i][8], //done
              current_pin_code: data_rec[i][9],
              permanent_street_number: data_rec[i][10],
              permanent_state_id: data_rec[i][11],
              permanent_city_id: data_rec[i][12],
              permanent_pin_code: data_rec[i][13],
              enrollment_number: data_rec[i][14],
              college_id: data_rec[i][15],
              qualification_id: data_rec[i][16],
              family_income: data_rec[i][17],
              pc_id: data_rec[i][18],
              subject_id: data_rec[i][19],
              skill_acquired: data_rec[i][20],
              required_subject_id: data_rec[i][21],
              skill_required: data_rec[i][22],
              gender: GENDERS[data_rec[i][23]] ? GENDERS[data_rec[i][23]] : 'M',
              beneficiary_type: data_rec[i][24].toLowerCase() === 'student' ? '1' : '0',
              institution_id: institution,
            }
            records.push(ele)
          }
        }
      }

      axios({
        method: 'POST',
        url: baseURL + 'addBeneficiaryBulk',
        data: records,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then((response) => {
          if (response.data.ErrorList.length > 0) {
            new swal({
              title: 'Check!',
              text: response.data.ErrorList,
              icon: 'warning',
              timer: 20000,
              button: false,
            }).then((result) => {
              setData([])
              setCols([])
            })
          } else {
            new swal({
              title: 'Done!',
              text: 'New records added successfully',
              icon: 'success',
              timer: 2000,
            }).then((result) => {
              setData([])
              setCols([])
            })
          }
        })
        .catch((error) => {
          console.log(error)
          // alert('Something went wrong. Please try again.')
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header as="h4" style={{ color: '#000', backgroundColor: '#ffffff' }}>
          <div style={{ float: 'right', marginBottom: '10px' }}>
            <Button
              className="btn primaryAdminButton"
              role="button"
              onClick={() => addBeneficiary()}
              style={{ marginLeft: '5px' }}
            >
              Add Beneficiary
            </Button>
            <Button
              className="btn primaryAdminButton"
              role="button"
              onClick={() => imageUploader.current.click()}
              style={{ marginLeft: '5px' }}
            >
              Import Beneficiaries
            </Button>
            <input
              className="edit_btn_photo"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: 'none',
              }}
            />
            <a
              href="/beneficiary_new.xlsx"
              style={{ fontSize: '14px', float: 'right', marginTop: '10px', marginLeft: '10px' }}
            >
              Download Template
            </a>
          </div>
        </Card.Header>
      </Card>
      {/* {cols.length === 0 ? 
      <DataTable columns={tableHeader} data={courses} pagination /> : */}
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            {/* <thead>
            <tr>
              {cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead> */}
            <tbody>
              {data.map((r, i) => (
                <tr key={i}>
                  {cols.map((c) => (
                    <td key={c.key}>{r[c.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {csvData.length > 0 && (
            <Button onClick={downloadCsv} className="primaryAdminButton">
              Download Incorrect Data
            </Button>
          )}
        </div>
      )}

      <Row>
        <Col>
          <Button
            className="btn primaryAdminButton"
            role="button"
            onClick={() => history.goBack()}
            style={{ marginTop: '15px' }}
          >
            Back
          </Button>
        </Col>
        <Col>
          {data.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
              <Button onClick={uploadBeneficiaries} className="primaryAdminButton">
                Confirm & Upload
              </Button>
            </div>
          ) : null}
        </Col>
      </Row>

      <Modal size="sm" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title>
            <Row>
              <Col>
                <p className="modalHeading">Change Password</p>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <CustomInput
              type="text"
              placeholder="New Password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <Button
            className="popupButtonFalse"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
          >
            CANCEL
          </Button>
          <Button
            className="popupButtonTrue"
            onClick={(e) => {
              e.preventDefault()
              successAction()
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="primary"
            onClick={() => deleteRecord()}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default List
