import React from "react";
import { Alert } from "react-bootstrap";

import "../VirtualAccountDetails.css";
import CustomInputField from "../../CustomInputField";
import PrimaryButton from "../../PrimaryButton";
import useVirtualAccountDetails from "./useVirtualAccountDetails";

const AddBankAccount = ({ show, setActiveTab }) => {
  const { addBankAccount, handleChange, values, error } =
    useVirtualAccountDetails();

  const onSubmit = async () => {
    let status = await addBankAccount(show);
    if (status) {
      setTimeout(() => {
        setActiveTab(2);
      }, 3000);
    }
  };

  return (
    <div>
      {error?.msg && <Alert variant={error?.type}>{error?.msg}</Alert>}
      <div className="flex">
        <div className="label-column">
          Bank Account Holder Name <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.name}
            name="name"
            type={"text"}
            placeholder=""
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Bank Name <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.bank_name}
            name="bank_name"
            type={"text"}
            placeholder=""
            value={values.bank_name}
            onChange={(e) => handleChange("bank_name", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Account No. <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.account_number}
            name="account_number"
            type={"text"}
            placeholder=""
            value={values.account_number}
            onChange={(e) => handleChange("account_number", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column border-bottom">
          IFSC Code <span className="required">*</span>
        </div>
        <div className="value-column border-bottom">
          <CustomInputField
            error={error?.ifsc_code}
            name="ifsc_code"
            type={"text"}
            placeholder=""
            value={values.ifsc_code}
            onChange={(e) => handleChange("ifsc_code", e.target.value)}
          />
        </div>
      </div>
      <div className="p-2 flex justify-center">
        <PrimaryButton
          label="Add"
          additionalStyle={{ width: "100px" }}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default AddBankAccount;
