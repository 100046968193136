// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-primary-button {
  /* width: 100%; */
  padding: 7px 10px;
  background-color: #002347;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new-primary-button:hover {
  background-color: #000d1a;
}

.status-box {
  background-color: #ff6666;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.status-box.active {
  background-color: #4caf50;
}

.new-trash-button {
  padding: 7px 10px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/common.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC","sourcesContent":[".new-primary-button {\n  /* width: 100%; */\n  padding: 7px 10px;\n  background-color: #002347;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.new-primary-button:hover {\n  background-color: #000d1a;\n}\n\n.status-box {\n  background-color: #ff6666;\n  color: #ffffff;\n  padding: 5px 10px;\n  border-radius: 4px;\n}\n\n.status-box.active {\n  background-color: #4caf50;\n}\n\n.new-trash-button {\n  padding: 7px 10px;\n  background-color: #ff6666;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
