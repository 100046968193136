import React, { useEffect } from "react";

import "../VirtualAccountDetails.css";
import StatusBox from "../../StatusBox";
import useVirtualAccountDetails from "./useVirtualAccountDetails";
import PrimaryButton from "../../PrimaryButton";
import SecondaryButton from "../../SecondaryButton";
import { Spinner } from "react-bootstrap";

const PendingInvoices = ({ show }) => {
  const { fetchRaisedInvoices, raisedInvoices, loading, respondOnInvoice } =
    useVirtualAccountDetails();

  useEffect(() => {
    if (show !== null) {
      fetchRaisedInvoices(show);
    }
  }, [show]);

  return (
    <div>
      {raisedInvoices?.length === 0 ? (
        <div className="flex justify-center items-center py-5">
          No invoice available.
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="label-column">Invoice No.</div>
            <div className="label-column">Program</div>
            <div className="label-column">Course</div>
            <div className="label-column">Vendor</div>
            <div className="label-column">Invoice Amount</div>
            <div
              className="label-column justify-center"
              style={{ justifyContent: "center" }}
            >
              Status
            </div>
            <div
              className="label-column justify-center"
              style={{ justifyContent: "center" }}
            >
              Invoice
            </div>
            <div
              className="label-column right-border justify-center"
              style={{ justifyContent: "center" }}
            >
              Action
            </div>
          </div>
          {raisedInvoices?.map((invoice) => {
            return (
              <div className="flex" key={invoice?.id}>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.unique_invoice_number}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.program?.program_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.courses?.course_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.institution?.institute_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  ₹{invoice?.invoice_amount}
                </div>
                <div className="value-column no-right-border border-bottom">
                  <div className="flex justify-center items-center">
                    <StatusBox
                      statusList={{
                        pending: { label: "Pending", bgColor: "#fcba03" },
                        paid: { label: "Paid", bgColor: "#4caf50" },
                        cancelled: { label: "Rejected", bgColor: "#ff6666" },
                      }}
                      statusValue={invoice?.status}
                    />
                  </div>
                </div>
                <div
                  className="value-column no-right-border border-bottom"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    href={`${invoice?.invoice_url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Invoice
                  </a>
                </div>
                <div
                  className="value-column border-bottom"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  {invoice?.status === "pending" && loading !== invoice.id && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "5px",
                        flexDirection: "column",
                      }}
                    >
                      <PrimaryButton
                        label="Approve"
                        onClick={() =>
                          respondOnInvoice(show, {
                            id: invoice?.id,
                            type: "paid",
                          })
                        }
                      />
                      <SecondaryButton
                        label="Reject"
                        onClick={() =>
                          respondOnInvoice(show, {
                            id: invoice?.id,
                            type: "cancelled",
                          })
                        }
                      />
                    </div>
                  )}
                  {loading === invoice?.id && (
                    <Spinner animation="border" variant="primary" />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default PendingInvoices;
