import React from "react";
import "./common.css";

const SecondaryButton = (props) => {
  return (
    <button onClick={props.onClick} className="new-trash-button">
      {props?.label ? props?.label : <i className="fas fa-trash"></i>}
    </button>
  );
};

export default SecondaryButton;
