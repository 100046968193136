import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Form from "react-bootstrap/Form";
// import { isISO8601Format } from "../utils/UtilityFunctions";
// import dayjs from "dayjs";

const CustomSingleSelectField = ({
  name,
  options = [],
  onChange,
  value,
  setKeyValue = {
    label: "label",
    value: "value",
  },
  isError = "",
  placeholder = "Select",
  label,
  maxWidth = null,
}) => {
  const animatedComponents = makeAnimated();
  const getOptionLabel = (option) => {
    let label = "";
    if (typeof setKeyValue.label === "object") {
      try {
        label =
          option[setKeyValue?.label?.level1Key][setKeyValue?.label?.level2Key];
      } catch (e) {
        label = undefined;
      }
    } else {
      label = option[setKeyValue.label];
    }

    if (label === undefined) {
      return "";
    }

    if (setKeyValue?.combinedLabel) {
      label += setKeyValue?.concatSymbol;
      // if (isISO8601Format(option[setKeyValue.combinedLabel])) {
      //   label += dayjs(option[setKeyValue.combinedLabel]).format(
      //     "DD MMM, YYYY"
      //   );
      // } else {

      if (typeof setKeyValue.combinedLabel === "object") {
        try {
          label +=
            option[setKeyValue?.combinedLabel?.level1Key][
              setKeyValue?.combinedLabel?.level2Key
            ];
        } catch (e) {
          console.error(e);
        }
      } else {
        label += option[setKeyValue.combinedLabel];
      }
      // }

      label += setKeyValue?.suffix || "";
    }
    return label;
  };
  const getOptionValue = (option) => option[setKeyValue.value];
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    let selected_opt = options?.filter((item) => {
      return item[setKeyValue.value] === value;
    });
    if (selected_opt?.length > 0) {
      setSelectedValue(selected_opt[0]);
    } else {
      setSelectedValue({ value: "", label: "-Select-" });
    }
  }, [value, options?.length]);

  return (
    <div style={maxWidth && { maxWidth: maxWidth }}>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        isMulti={false}
        name={name}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        components={animatedComponents}
        className="basic-multi-select text-xs h-25"
        classNamePrefix="select font-13px input"
        classNames={"1111111"}
        closeMenuOnScroll={"22222"}
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "Select"}
        styles={{
          control: (provided) => ({
            ...provided,
            height: "30px !important", // Set input field height to 30px
            // fontSize: '16px !important'
          }),
          option: (provided) => ({
            ...provided,
            fontSize: "10px", // Set font size for dropdown options to 12px
          }),
        }}
      />
      {isError.length > 0 && (
        <span
          style={{
            fontSize: "12px",
            color: "red",
            marginLeft: "2px",
          }}
        >
          {isError}
        </span>
      )}
    </div>
  );
};

export default CustomSingleSelectField;
