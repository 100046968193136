import React, { useEffect } from "react";

import "../VirtualAccountDetails.css";
import StatusBox from "../../StatusBox";
import useVirtualAccountDetails from "./useVirtualAccountDetails";

const RaisedInvoices = ({ show }) => {
  const { fetchRaisedInvoices, raisedInvoices } = useVirtualAccountDetails();

  useEffect(() => {
    if (show !== null) {
      fetchRaisedInvoices(show);
    }
  }, [show]);

  return (
    <div>
      {raisedInvoices?.length === 0 ? (
        <div className="flex justify-center items-center py-5">
          No invoice available.
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="label-column">Invoice No.</div>
            <div className="label-column">Program</div>
            <div className="label-column">Course</div>
            <div className="label-column">Vendor</div>
            <div className="label-column">Invoice Amount</div>
            <div
              className="label-column justify-center"
              style={{ justifyContent: "center" }}
            >
              Status
            </div>
            <div
              className="label-column right-border justify-center"
              style={{ justifyContent: "center" }}
            >
              Invoice
            </div>
          </div>
          {raisedInvoices?.map((invoice) => {
            return (
              <div className="flex" key={invoice?.id}>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.unique_invoice_number}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.program?.program_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.courses?.course_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {invoice?.institution?.institute_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  ₹{invoice?.invoice_amount}
                </div>
                <div className="value-column no-right-border border-bottom">
                  <div className="flex justify-center items-center">
                    <StatusBox
                      statusList={{
                        pending: { label: "Pending", bgColor: "#fcba03" },
                        paid: { label: "Paid", bgColor: "#4caf50" },
                        cancelled: { label: "Rejected", bgColor: "#ff6666" },
                      }}
                      statusValue={invoice?.status}
                    />
                  </div>
                </div>
                <div
                  className="value-column border-bottom"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    href={`${invoice?.invoice_url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Invoice
                  </a>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default RaisedInvoices;
