import { useEffect, useState } from "react";

import axios from "axios";
import { baseURL } from "../../../config/baseURLPath";
import { validPositiveNumber } from "../../../utils/UtilityFunctions";
import moment from "moment";

const BLANK_REQUEST = {
  name: "",
  bank_name: "",
  account_number: "",
  ifsc_code: "",
};

const BLANK_INVOICE_PARAMS = {
  unique_invoice_number: "",
  virtual_bank_account_id: "",
  real_bank_account_id: "",
  invoice_amount: "",
  account_amount: "",
  balance: "",
  course_id: "",
  program_id: "",
  sponsor_id: "",
  vendor_id: "",
  invoice_url: "",
  status: "pending",
};

const useVirtualAccountDetails = () => {
  const [loading, setLoading] = useState(false);

  const [virtualAccountDetails, setVirtualAccountDetails] = useState(null);
  const [virtualUserId, setVirtualUserId] = useState("");
  const [bankAccounts, setBankAccounts] = useState(null);

  const [values, setValues] = useState(BLANK_REQUEST);
  const [error, setErrors] = useState(null);

  const [invoiceParams, setInvoiceParams] = useState(BLANK_INVOICE_PARAMS);
  const [invoiceParamError, setInvoiceParamError] = useState(null);

  const [courseList, setCourseList] = useState([]);
  const [programList, setProgramsList] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const [raisedInvoices, setRaisedInvoices] = useState([]);

  useEffect(() => {
    setInvoiceParamError(null);
  }, [selectedFile]);

  const handleChange = (name, val) => {
    if (error !== null) {
      setErrors(null);
    }
    setValues({ ...values, [name]: val });
  };

  const addBankAccount = async (show) => {
    if (values?.name?.trim() === "") {
      setErrors({ name: "Please enter name" });
      return;
    }
    if (values?.bank_name?.trim() === "") {
      setErrors({ bank_name: "Please enter bank name" });
      return;
    }
    if (values?.account_number?.trim() === "") {
      setErrors({ account_number: "Please enter Account No." });
      return;
    }
    if (values?.ifsc_code?.trim() === "") {
      setErrors({ ifsc_code: "Please enter IFSC code" });
      return;
    }
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };

    let reqParams = { ...values, ...show };

    await axios
      .post(baseURL + "saveNewAccount", reqParams, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setValues(BLANK_REQUEST);
          setErrors({
            msg: "Account details successfully added",
            type: "success",
          });
          return true;
        } else {
          setErrors({
            msg: response.data?.Message || "No record found on TrustMore",
            type: "warning",
          });
        }
      })
      .catch(function (error) {});
    return false;
  };

  const fetchDetails = async (show) => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "fetchVirtualAccountDetails", show, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setVirtualAccountDetails(response.data?.Data?.data);
          setVirtualUserId(response.data?.MyId);
        } else {
          setVirtualAccountDetails({
            Message: response.data?.Message || "No record found on TrustMore",
          });
        }
      })
      .catch(function (error) {});
  };

  const fetchAddedBanks = async (show) => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "getAllAddedBankAccounts", show, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setBankAccounts(response.data?.Data?.data);
        } else {
          setBankAccounts({
            Message: response.data?.Message || "No record found on TrustMore",
          });
        }
      })
      .catch(function (error) {});
  };

  const handleInvoiceParamChange = (name, value) => {
    if (invoiceParamError !== null) {
      setInvoiceParamError(null);
    }
    if (name === "invoice_amount") {
      if (parseInt(value) > virtualAccountDetails?.balance) {
        setInvoiceParamError({
          invoice_amount:
            "Invoice amount cannot be greater than balance amount.",
        });
        return;
      }
      if (validPositiveNumber(value) || value === "") {
        setInvoiceParams({ ...invoiceParams, [name]: value });
        return;
      }
      return;
    }

    if (name === "course_id") {
      fetchCoursePrograms(value);
      setInvoiceParams({ ...invoiceParams, [name]: value, program_id: "" });
    }

    setInvoiceParams({ ...invoiceParams, [name]: value });
  };

  const fetchVendorCourses = async () => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };

    const userData = JSON.parse(localStorage.getItem("user"));

    let params = {
      institution_id: userData.AdminUser.institution_id,
    };

    await axios
      .post(baseURL + "searchCourses", params, jwtHeader)
      .then(function (response) {
        if (response.data?.Content?.length > 0) {
          setCourseList(response.data?.Content);
          return;
        }
        setCourseList([]);
      })
      .catch(function (error) {
        setCourseList([]);
      });
  };

  const fetchCoursePrograms = async (courseId) => {
    if (courseId === "") {
      setProgramsList([]);
      return;
    }

    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };

    let params = {
      course_id: courseId,
    };

    await axios
      .post(baseURL + "getCoursePrograms", params, jwtHeader)
      .then(function (response) {
        if (response.data?.Content?.length > 0) {
          setProgramsList(response.data?.Content);
          return;
        }
        setProgramsList([]);
      })
      .catch(function (error) {
        setProgramsList([]);
      });
  };

  const randomFourDigit = () => Math.floor(1000 + Math.random() * 9000);

  const raiseInvoice = async (show) => {
    if (invoiceParams?.real_bank_account_id === "") {
      setInvoiceParamError({
        real_bank_account_id:
          "Please select your bank account to deposit amount.",
      });
      return;
    }
    if (invoiceParams?.invoice_amount < 1) {
      setInvoiceParamError({
        invoice_amount: "Please enter valid invoice amount.",
      });
      return;
    }
    if (invoiceParams?.course_id === "") {
      setInvoiceParamError({ course_id: "Please select course." });
      return;
    }
    if (invoiceParams?.program_id === "") {
      setInvoiceParamError({ program_id: "Please select program." });
      return;
    }

    if (!selectedFile) {
      setInvoiceParamError({
        invoice_attachment: "Please select invoice file.",
      });
    }
    const formData = new FormData();

    const multiPartHeader = {
      headers: {
        "access-token": localStorage.getItem("csr_token"),
        "Content-Type": "multipart/form-data",
      },
    };

    setLoading(true);
    formData.append("trustMoreId", virtualUserId);
    formData.append("programId", invoiceParams?.program_id);
    formData.append("file", selectedFile);

    try {
      let uploadedFile = await axios.post(
        baseURL + "uploadFileToVA",
        formData,
        multiPartHeader
      );
      if (uploadedFile?.data?.Content?.length > 0) {
        // File Uploaded successfully.

        const jwtHeader = {
          headers: { "access-token": localStorage.getItem("csr_token") },
        };

        let selectedProgram = programList?.filter(
          (program) => program?.program_id === invoiceParams?.program_id
        );

        let reqParams = {
          ...invoiceParams,
          unique_invoice_number: `${moment().format("YYYY-MM-DD")}-${
            invoiceParams?.program_id
          }-${randomFourDigit()}`,
          account_amount: virtualAccountDetails?.balance,
          balance:
            parseInt(virtualAccountDetails?.balance) -
            parseInt(invoiceParams?.invoice_amount),
          virtual_bank_account_id: virtualUserId,
          invoice_url: uploadedFile?.data?.Content[0]?.url,
          sponsor_id: selectedProgram[0]?.sponsor_id,
          vendor_id: show?.user_id,
        };

        await axios
          .post(baseURL + "raiseInvoice", reqParams, jwtHeader)
          .then(function (response) {
            if (response.data?.Status) {
              setInvoiceParams(BLANK_INVOICE_PARAMS);
              setInvoiceParamError({
                msg: "Invoice successfully submitted.",
                type: "success",
              });
              setLoading(false);
              return true;
            } else {
              setInvoiceParamError({
                msg: response.data?.Message || "No record found on TrustMore",
                type: "warning",
              });
            }
          })
          .catch(function (error) {
            setInvoiceParamError({
              msg: "We are not able to upload file. Please try again later.",
              type: "error",
            });
          });
        setLoading(false);
        return false;
      }
    } catch (e) {
      setLoading(false);
      setInvoiceParamError({
        msg: "We are not able to upload file. Please try again later.",
        type: "error",
      });
    }
  };

  const fetchRaisedInvoices = async (show) => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "getAllRaisedInvoices", show, jwtHeader)
      .then(function (response) {
        if (response.data?.Content) {
          setRaisedInvoices(response.data?.Content);
        } else {
          setRaisedInvoices([]);
        }
      })
      .catch(function (error) {
        setRaisedInvoices([]);
      });
  };

  const respondOnInvoice = async (show, invoice) => {
    setLoading(invoice?.id);
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "respondOnInvoice", { ...show, ...invoice }, jwtHeader)
      .then(function (response) {
        if (response.data?.Status === 200) {
          fetchRaisedInvoices(show);
          setLoading(false);
        } else {
          fetchRaisedInvoices(show);
          setLoading(false);
          alert(response.data?.Message);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return {
    fetchDetails,
    virtualAccountDetails,
    addBankAccount,
    handleChange,
    values,
    error,
    bankAccounts,
    fetchAddedBanks,
    invoiceParams,
    invoiceParamError,
    handleInvoiceParamChange,
    fetchVendorCourses,
    courseList,
    fetchCoursePrograms,
    programList,
    selectedFile,
    setSelectedFile,
    raiseInvoice,
    loading,
    fetchRaisedInvoices,
    raisedInvoices,
    respondOnInvoice,
  };
};
export default useVirtualAccountDetails;
