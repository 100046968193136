import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import "./VirtualAccountDetails.css";
import VirtualAccount from "./components/VirtualAccount";
import AddBankAccount from "./components/AddBankAccount";
import AddedBankAccounts from "./components/AddedBankAccounts";
import RaiseInvoice from "./components/RaiseInvoice";
import RaisedInvoices from "./components/RaisedInvoices";
import PendingInvoices from "./components/PendingInvoices";

const TABS = ["VIRTUAL ACCOUNT", "ADD BANK ACCOUNT", "ALL BANK ACCOUNTS"];
const VENDOR_TABS = ["RAISE INVOICE", "RAISED INVOICES"];
const SPONSOR_TABS = ["INVOICES"];

const VirtualAccountDetails = ({ handleClose, show, type }) => {
  const [activeTab, setActiveTab] = useState(0);
  const ALL_TABS = TABS.concat(type === "vendor" ? VENDOR_TABS : SPONSOR_TABS);
  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="virtual-modal-heading">Virtual Account Details</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="virtual-account-modal">
          {ALL_TABS?.map((tab, index) => {
            return (
              <Tab
                index={index}
                key={index.toString()}
                item={tab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </div>
        {activeTab === 0 && <VirtualAccount show={show} />}
        {activeTab === 1 && (
          <AddBankAccount show={show} setActiveTab={setActiveTab} />
        )}
        {activeTab === 2 && <AddedBankAccounts show={show} />}
        {activeTab === 3 && type === "vendor" && <RaiseInvoice show={show} />}
        {activeTab === 4 && type === "vendor" && <RaisedInvoices show={show} />}
        {activeTab === 3 && type === "sponsor" && (
          <PendingInvoices show={show} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VirtualAccountDetails;

const Tab = ({ index, item, activeTab, setActiveTab }) => {
  return (
    <button
      className={`tab ${index === activeTab ? "active" : ""}`}
      onClick={() => setActiveTab(index)}
    >
      {item}
    </button>
  );
};
