import React, { useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";

import "../VirtualAccountDetails.css";
import CustomInputField from "../../CustomInputField";
import PrimaryButton from "../../PrimaryButton";
import useVirtualAccountDetails from "./useVirtualAccountDetails";
import CustomSingleSelectField from "../../CustomSingleSelectField";

const RaiseInvoice = ({ show, setActiveTab }) => {
  const {
    fetchAddedBanks,
    bankAccounts,
    invoiceParams,
    invoiceParamError,
    handleInvoiceParamChange,
    fetchVendorCourses,
    courseList,
    programList,
    fetchDetails,
    virtualAccountDetails,
    // selectedFile,
    setSelectedFile,
    raiseInvoice,
    loading,
  } = useVirtualAccountDetails();

  useEffect(() => {
    fetchAddedBanks(show);
    fetchVendorCourses();
    fetchDetails(show);
  }, []);

  const onSubmit = async () => {
    let status = await raiseInvoice(show);
    if (status) {
      setTimeout(() => {
        setActiveTab(2);
      }, 3000);
    }
  };

  return (
    <div>
      {invoiceParamError?.msg && (
        <Alert variant={invoiceParamError?.type}>
          {invoiceParamError?.msg}
        </Alert>
      )}
      <p className="form-title">Raise Invoice to withdraw amount.</p>
      <div className="flex">
        <div className="label-column">Balance</div>
        <div className="value-column">₹{virtualAccountDetails?.balance}</div>
      </div>
      <div className="flex">
        <div className="label-column">
          Invoice Amount <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={invoiceParamError?.invoice_amount}
            name="invoice_amount"
            type={"text"}
            placeholder=""
            value={invoiceParams.invoice_amount}
            onChange={(e) =>
              handleInvoiceParamChange("invoice_amount", e.target.value)
            }
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Select Account <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomSingleSelectField
            isError={invoiceParamError?.real_bank_account_id}
            options={bankAccounts}
            setKeyValue={{
              label: "bank_account",
              value: "id",
              combinedLabel: "bank_name",
              concatSymbol: " (",
              suffix: ")",
            }}
            name={"real_bank_account_id"}
            value={invoiceParams.real_bank_account_id}
            onChange={(e) => {
              handleInvoiceParamChange("real_bank_account_id", e.id);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Course <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomSingleSelectField
            isError={invoiceParamError?.course_id}
            options={courseList}
            setKeyValue={{
              label: "course_name",
              value: "course_id",
              combinedLabel: "",
              concatSymbol: "",
              suffix: "",
            }}
            name={"course_id"}
            value={invoiceParams.course_id}
            onChange={(e) => {
              handleInvoiceParamChange("course_id", e.course_id);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column border-bottom">
          Program<span className="required">*</span>
        </div>
        <div className="value-column border-bottom">
          <CustomSingleSelectField
            isError={invoiceParamError?.program_id}
            options={programList}
            setKeyValue={{
              label: "program_name",
              value: "program_id",
              combinedLabel: "",
              concatSymbol: "",
              suffix: "",
            }}
            name={"program_id"}
            value={invoiceParams.program_id}
            onChange={(e) => {
              handleInvoiceParamChange("program_id", e.program_id);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column border-bottom">
          Invoice File <span className="required">*</span>
        </div>
        <div className="value-column border-bottom">
          <CustomInputField
            error={invoiceParamError?.invoice_attachment}
            name="invoice_attachment"
            type={"file"}
            placeholder=""
            // value={invoiceParams.invoice_attachment}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            accept={".pdf"}
          />
        </div>
      </div>
      <div className="p-2 flex justify-center">
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <PrimaryButton
            label="Submit"
            additionalStyle={{ width: "100px" }}
            onClick={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default RaiseInvoice;
