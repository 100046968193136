import React, { useEffect } from "react";

import "../VirtualAccountDetails.css";
import useVirtualAccountDetails from "./useVirtualAccountDetails";

const VirtualAccount = ({ show }) => {
  const { fetchDetails, virtualAccountDetails } = useVirtualAccountDetails();
  useEffect(() => {
    if (show !== null) {
      fetchDetails(show);
    }
  }, [show]);

  return (
    <div>
      {virtualAccountDetails?.Message ? (
        <div className="flex justify-center items-center py-5">
          {virtualAccountDetails?.Message}
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="label-column">Name</div>
            <div className="value-column">{virtualAccountDetails?.name}</div>
          </div>
          <div className="flex">
            <div className="label-column">Account Number</div>
            <div className="value-column">
              {virtualAccountDetails?.acccount_number}
            </div>
          </div>
          <div className="flex">
            <div className="label-column">Balance</div>
            <div className="value-column">
              ₹{virtualAccountDetails?.balance}
            </div>
          </div>
          <div className="flex">
            <div className="label-column">Bank Name</div>
            <div className="value-column">
              {virtualAccountDetails?.bank_name}
            </div>
          </div>
          <div className="flex">
            <div className="label-column border-bottom">IFSC Code</div>
            <div className="value-column border-bottom">
              {virtualAccountDetails?.ifsc_code}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VirtualAccount;
